<template>
  <HCard
    :to="`/acheter/annonces/${property.url}`"
    class="property-card"
    color="white"
    no-padding
  >
    <NuxtImg
      format="webp"
      :src="property.picture"
      :alt="property.name"
      loading="lazy"
      :style="{
        aspectRatio: '17/15',
        objectFit: 'cover'
      }"
      width="470"
      sizes="306px sm:442px md:258px lg:286px"
      quality="80"
    />
    <div
      v-if="stickerText(property)"
      class="sticker"
    >
      {{ stickerText(property) }}
    </div>
    <div class="property-card__content p-3 pb-4">
      <p class="property-card__features">
        {{ property.rooms && property.rooms > 1 ? `${property.rooms} pièces` : `${property.rooms} pièce` }} • {{ property.area }}&nbsp;m² • {{ property.formattedCity }}, {{ property.postcode }}
      </p>
      <p
        color="auto"
        type="3"
        tag="p"
        class="property-card__title"
      >
        {{ property.name }}
      </p>
      <p class="property-card__price">
        {{ numberWithSpace(Math.round(property.price)) }}&nbsp;€
      </p>
    </div>
  </HCard>
</template>

<script setup lang="ts">
const { property } = defineProps<{
  property: any
}>()

function stickerText (property: any) {
  if (property?.preselling &&
    !property?.offer &&
    !property?.precontract &&
    !property?.option) {
    return 'AVANT-PREMIÈRE'
  } else if (property?.offer) {
    return 'SOUS OFFRE'
  } else if (property?.precontract) {
    return 'SOUS COMPROMIS'
  } else if (property?.option && !property?.off_market) {
    return 'SOUS OPTION'
  }
  return null
}
</script>

<style lang="scss" scoped>
.property-card {
  position: relative;
  height: 100%;
  text-decoration: none;
  color: $night-blue;

  &__content {
    display: flex;
    flex-flow: column wrap;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 1em;
    flex-grow: 2;
    text-overflow: ellipsis;
    height: 54px;
    overflow: hidden;
    display: inline-block;
    display: -webkit-box;
    -webkit-line-clamp: 2; // max nb lines to show
    -webkit-box-orient: vertical;
  }

  &__features {
    height: 54px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; // max nb lines to show
    -webkit-box-orient: vertical;
  }

  &__features,
  &__city {
    font-size: 0.875rem;
  }

  &__price {
    font-size: 1.875rem;
    line-height: 100%;
    color: $purple;
    font-weight: 700;
    margin-top: 0.5em;
  }

  .sticker {
    background-color: $purple;
    position: absolute;
    font-size: 0.9rem;
    top: 1em;
    right: 2em;
    color: white;
    padding: 0.3em 0.8em;
    border-radius: 50px;
    font-weight: 600;
  }
}
</style>
